<template>
  <v-app v-if="$auth.ready()">
    <app-drawer
      :drawer.sync="drawer"
      @drawer-update="updateDrawer"
    ></app-drawer>

    <v-app-bar app clipped-left color="white" flat elevate-on-scroll>
      <v-toolbar-title flat>
        <v-btn
          link
          color="white"
          width="230px"
          height="55px"
          :to="{ path: '/dashboard' }"
          class="hidden-md-and-down white--text"
        >
          <v-img
            max-width="120"
            :src="require('@/assets/dekra-arbeit-gruppe-logo.png')"
            alt="Dekra"
          />
        </v-btn>
      </v-toolbar-title>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="grey"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" id="notificationsMenu">
              <v-badge :content="newNotificationsBadge" color="primary" overlap>
                <template v-slot:badge>{{ newNotificationsBadge }}</template>
                <v-icon size="30" color="grey">
                  mdi-bell-outline
                </v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Notifications</span>
        </v-tooltip>
        <notifications-menu></notifications-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              <v-avatar
                v-if="$auth.check()"
                size="25"
                color="white"
                class="mr-2"
              >
                <v-icon color="grey lighten-1" large>
                  mdi-account-circle
                </v-icon>
              </v-avatar>
              <span v-if="$auth.check()" class="d-none d-sm-flex">{{
                _user.name
              }}</span>
              <v-icon right color="grey">
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="{ name: 'my-account' }">
              My account
            </v-list-item>
            <v-list-item @click="logout()">
              Logout
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height dekra-box-shadow align-start pa-0" fluid>
        <transition name="router-fade" mode="out-in">
          <!--<keep-alive exclude="show-workforce">
          </keep-alive>-->

          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </v-container>
    </v-main>
    <v-footer color="grey lighten-3" app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    AppDrawer: () => import("@/components/core/AppDrawer"),
    NotificationsMenu: () =>
      import("@/components/notifications/NotificationsMenu")
  },
  data() {
    return {
      search: "",
      drawer: true
    };
  },
  created() {
    this.getNotifications();
  },
  mounted() {
    this.$auth.user(this._user);
  },
  computed: {
    ...mapGetters({
      newNotificationsBadge: "notifications/newNotificationsBadge"
    }),
    _user() {
      return JSON.parse(this.$auth.remember()) || {};
    }
  },
  methods: {
    updateDrawer(draw) {
      this.drawer = draw;
    },
    async getNotifications() {
      await this.$store.dispatch("notifications/getNewNotifications", {
        id: this._user.id,
        unseen: true
      });
    },
    logout() {
      this.$router.push({ name: "logout" });
    },
    goToSearchPage() {
      this.$router.push({ name: "search", params: { search: this.search } });
    }
  }
};
</script>
<style>
.dekra-box-shadow {
  box-shadow: 0px 0px 10px -4px grey inset;
}
</style>
